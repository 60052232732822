import React, { useState, useEffect } from 'react';
import { useAsyncRetry } from 'react-use';
import { Verifyok } from '../../icons/';
import { Page } from '../../model';
import axios from 'axios';
import api from '../../api';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ReactInputVerificationCode from 'react-input-verification-code';
import NoticeModel from '../../components/NoticeModel';
import * as Icons from '../../icons';
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';

import {
  REGEX_EMAIL,
  GOOGLE_CLIENT_ID,
  LINE_LOGIN_CLIENT_ID,
  LINE_LOGIN_SECRET,
  LINE_LOGIN_URI,
  FB_CLIENT_ID,
} from '../../constants';
import SubmitModel from '../../components/SubmitModel';
import { loginInfo } from '../../model';
import classNames from 'classnames/bind';
import style from './index.module.css';
import qs from 'querystring';
import { set } from 'lodash';
import KGILogo from '../../static/image/KGI_Logo.png';
const cx = classNames.bind(style);
export interface VerifyProps {
  showMsg: (msg: string) => void;
  VerifyCheckRetry: () => void;
  defauleCuid: string | null;
  phoneIsVerify: boolean;
  w1: string;
  m2: string;
  VerifyCheckLoading: boolean;
  setPageButton: (page: Page) => void;
  setUserIslogin: (isLogin: boolean) => void;
  setLoginInfo: (loginInfo: loginInfo) => void;
  setPasswordModelShow: (state: boolean) => void;
  setPasswordChangeType: (type: string) => void;
  // verified: string[],
  // remindmsg: string[],
}
function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
const Verify: React.FC<VerifyProps> = ({
  phoneIsVerify,
  showMsg,
  VerifyCheckRetry,
  setPageButton,
  defauleCuid,
  w1,
  m2,
  VerifyCheckLoading,
  setUserIslogin,
  setLoginInfo,
  setPasswordModelShow,
  setPasswordChangeType,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCanSee, setPasswordCanSee] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [submitModalShow, setSubmitModalShow] = useState(false);
  const [loginByThirdParty, setLoginByThirdParty] = useState('');
  const [fbProfile, setFbProfile] = useState({});
  const [googleProfile, setGoogleProfile] = useState({});
  const [lineProfile, setLineProfile] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [noticeModelShow, setNoticeModelShow] = useState(false);
  const login = async () => {
    if (email === '' || password === '') {
      setErrMsg('請輸入帳號密碼，帳號密碼不可為空');
      setModalShow(true);
      return;
    }
    if (!REGEX_EMAIL.test(email)) {
      setErrMsg('請確認email格式是否正確');
      setModalShow(true);
      return;
    }
    const data = await api.userLogin({ email, password });
    if (data.code === 0) {
      setLoginInfo({
        email: data.mail,
        phone: data.phone,
        uid: data.uid,
      });
      setUserIslogin(true);
      window.sessionStorage.setItem('LOGIN_TYPE', 'email');
      setPageButton('gameIndex');
    }
  };
  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (urlParams.has('code') && urlParams.has('state')) {
      lineUserLogin(`${urlParams.get('code')}`, `${urlParams.get('state')}`);
    } else if (code) {
      localStorage.setItem('code', code);
    } else {
      localStorage.setItem('code', '');
    }
  }, []);
  async function getUserProfile(accessToken: string) {
    const profileEndpoint = 'https://api.line.me/v2/profile';

    const response = await axios.get(profileEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

      const storedCodeBase64 = localStorage.getItem('code') || '';
      let storedCode = '';
      try {
        storedCode = atob(storedCodeBase64);
      } catch (error) {
        console.error("Base64 解碼失敗，可能是無效的編碼:", error);
        localStorage.setItem('code', ''); // 如果無法解密 直接給空值
      }
      
      const lineUserLogin = async (code: string, state: string) => {
        console.log('Received code from LINE authorization:', code);
      
        const body = {
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: LINE_LOGIN_URI,
          client_id: LINE_LOGIN_CLIENT_ID,
          client_secret: LINE_LOGIN_SECRET,
          scope: 'profile%20openid%20email',
        };
      
        let res = await axios.post(
          'https://api.line.me/oauth2/v2.1/token',
          qs.stringify(body),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
      
        if (res.data) {
          const lineEmail = parseJwt(res.data.id_token).email;
          if (!lineEmail) {
            setNoticeModelShow(true);
            return;
          }
          const profile = await getUserProfile(res.data.access_token);
      
          const encodedCode = localStorage.getItem('code') || code; // 使用編碼的 code
          setLineProfile({
            lineid: profile.userId as any,
            linename: profile.displayName,
            linemail: lineEmail,
            regsource: atob(encodedCode),
          });
      
          const lineLoginRes = await api.lineUserLogin({
            lineid: profile.userId as any,
            linename: profile.displayName,
            linemail: lineEmail,
            name: '',
            phone: '',
            regsource: atob(encodedCode),
          });
      
          if (lineLoginRes.code === '0000') {
            if (encodedCode) {
              const newUrl = new URL(window.location.origin);
              newUrl.searchParams.set('code', encodedCode); // 保持 URL 中的 code 為編碼形式
              window.history.pushState({}, document.title, newUrl.toString());
            }
            setLoginInfo({
              email: lineLoginRes.mail,
              phone: lineLoginRes.phone,
              uid: lineLoginRes.uid,
            });
            window.sessionStorage.setItem('LOGIN_TYPE', 'line');
            setUserIslogin(true);
            setPageButton('gameIndex');
          } else {
            setLoginByThirdParty('line');
            setSubmitModalShow(true);
          }
        }
      };
      
  
  const lineSignIn = async () => {
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_LOGIN_CLIENT_ID}&redirect_uri=${LINE_LOGIN_URI}&state=1qaz@WSX&scope=profile%20openid%20email`;
  };

  const responseGoogle = async (res: any) => {
    if (res) {
      setGoogleProfile({
        goid: res.profileObj.googleId,
        goname: res.profileObj.name,
        goemail: res.profileObj.email,
        regsource : storedCode,
      });
      console.log('res', res.profileObj);

      const googleLoginPhpRes = await api.googleUserLogin({
        goid: res.profileObj.googleId,
        goname: res.profileObj.name,
        goemail: res.profileObj.email,
      });
      if (googleLoginPhpRes.code === 3) {
        setLoginByThirdParty('google');
        setSubmitModalShow(true);
        return;
      } else if (googleLoginPhpRes.code === 0) {
        setLoginInfo({
          email: googleLoginPhpRes.mail,
          phone: googleLoginPhpRes.phone,
          uid: googleLoginPhpRes.uid,
        });
        window.sessionStorage.setItem('LOGIN_TYPE', 'google');
        setUserIslogin(true);
        setPageButton('gameIndex');
      } else {
        toast.error(
          `登入失敗${
            ':(' + googleLoginPhpRes.code + ')' + googleLoginPhpRes.msg
          }`,
          {
            position: 'top-right',
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        // return;
      }
    }
  };
  const FBLogin = async () => {
    window.FB.login(
      function (response: any) {
        console.log('fblogin', response);
        if (response.status === 'connected') {
          // init();
          window.FB.api('/me?fields=id,name,email', async function (res: any) {
            console.log('Good to see you, ' + res.email);
            console.log('Good to see you, ' + res.id);
            console.log('Good to see you, ' + res.name);
            setFbProfile({
              fbid: res.id,
              fbname: res.name,
              fbemail: res.email,
            });

            const fbLoginPhpRes = await api.fbUserLogin({
              fbid: res.id,
              fbname: res.name,
              fbemail: res.email,
            });
            if (fbLoginPhpRes.code === 3) {
              setLoginByThirdParty('fb');
              setSubmitModalShow(true);
              return;
            } else if (fbLoginPhpRes.code === 0) {
              setLoginInfo({
                email: fbLoginPhpRes.mail,
                phone: fbLoginPhpRes.phone,
                uid: fbLoginPhpRes.uid,
              });
              window.sessionStorage.setItem('LOGIN_TYPE', 'fb');
              setUserIslogin(true);
              setPageButton('gameIndex');
            }
          });
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  // const responseFacebook = async (res: any) => {
  //   console.log('res', res);
  //   if (res) {
  //     setFbProfile({
  //       fbid: res.id,
  //       fbname: res.name,
  //       fbemail: res.email,
  //     });
  //     if (loginByThirdParty === 'fb') {
  //       const fbLoginPhpRes = await api.fbUserLogin({
  //         fbid: res.id,
  //         fbname: res.name,
  //         fbemail: res.email,
  //       });
  //       if (fbLoginPhpRes.code === 3) {
  //         setLoginByThirdParty('fb');
  //         setSubmitModalShow(true);
  //         return;
  //       } else if (fbLoginPhpRes.code === 0) {
  //         setLoginInfo({
  //           email: fbLoginPhpRes.mail,
  //           phone: fbLoginPhpRes.phone,
  //           uid: fbLoginPhpRes.uid,
  //         });
  //         setUserIslogin(true);
  //         setPageButton('gameIndex');
  //       }
  //     }
  //   }
  // };

  return (
    <div className={cx('')}>
      <div className={cx('row', 'IpLogoBg')}>
        <div className={cx('w-25', 'IpLogo', 'h-100')}>
          <img className="h-100" src={KGILogo}></img>
        </div>
      </div>
      <Carousel>
       <Carousel.Item>
        <div
          className={cx('carsouelItem','m-auto')}
          onClick={() => {
            window.location.href = 'https://www.youtube.com/@KGISIA.channel';
          }}
        ></div>
      </Carousel.Item> 
       <Carousel.Item>
        <div
          className={cx('carsouelItem2','m-auto')}
          onClick={() => {
            window.location.href = 'https://www.youtube.com/playlist?list=PLQMOucYPyMXZiyIoaDpoi4tO74mbdjXS3';
          }}
        ></div>
      </Carousel.Item> 
      <Carousel.Item>
        <div
          className={cx('carsouelItem3',)}
          onClick={() => {
            window.location.href = 'https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401&_gl=1*1hee11x*_gcl_au*MTE2MTU4MDI0MS4xNzMwNjk5OTMw*_ga*MTUwMjQ2ODM1Ny4xNzA2ODY0MjAw*_ga_K1BGJY4WFR*MTczNTEwODg5Ni4yOTkuMS4xNzM1MTA4OTY2LjYwLjAuMA';
          }}
        ></div>
      </Carousel.Item> 
      </Carousel>
      {/* <div className={cx('row', 'mb-4')}>
        <div
          className={cx('m-auto', 'IpBanner')}
          onClick={() => {
            window.location.href =
              'https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401';
          }}
        >

        </div>
      </div> */}
      <div
        className={cx(
          'd-flex',
          'justify-content-between',
          'align-items-center',

          'my-3'
        )}
      >
        <hr
          style={{
            width: '20%',
            height: 2,
            color: '#000',
          }}
        />
        <span>請先完成註冊才能登入</span>
        <hr
          style={{
            width: '20%',
            height: 2,
            color: '#000',
          }}
        />
      </div>
      <div className={cx('row', 'flex-direction-col')}>
        <div className={cx('col-10', 'm-auto', 'mt-3', 'opacity-60')}>
          <input
            type="text"
            className={cx('form-control', 'form-control-lg')}
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={cx('col-10', 'm-auto', 'mt-1', 'opacity-60')}>
          <div className={cx('mt-2')}>
            <div
              className={cx(
                'w-100',
                'h-100',
                'd-flex',
                'form-control',
                'form-control-lg',
                'position-relative',
                'p-0',
                'align-items-center'
              )}
            >
              <div className={cx('position-absolute', 'lockIcon')}>
                <Icons.Lock width={25} height={25} fill="#aaa" />
              </div>
              <input
                type={passwordCanSee ? 'text' : 'password'}
                className={cx('w-100', 'border-0', 'passwordInput')}
                placeholder="密碼"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <div
                className={cx('position-absolute', 'eyeIcon')}
                onClick={() => {
                  setPasswordCanSee(!passwordCanSee);
                }}
              >
                <Icons.Eye
                  width={25}
                  height={25}
                  fill={passwordCanSee ? '#000' : '#aaa'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cx('col-10', 'm-auto', 'mt-3')}>
          <Button
            onClick={() => {
              login();
            }}
            className={cx('form-control', 'form-control-lg', 'loginBtn')}
            size="lg"
          >
            登入
          </Button>
        </div>
        <div
          className={cx(
            'col-10',
            'm-auto',
            'mt-5',
            'mb-3',
            'd-flex',
            'justify-content-between'
          )}
        >
          <span
            className={cx('buttonFrag', 'hyperLinkText')}
            onClick={() => setSubmitModalShow(true)}
          >
            我要註冊
          </span>
          <span
            className={cx('buttonFrag', 'hyperLinkText')}
            onClick={() => {
              setPasswordChangeType('forget');
              setPasswordModelShow(true);
            }}
          >
            忘記密碼
          </span>
        </div>
      </div>
      <div
        className={cx(
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'my-3'
        )}
      >
        <hr
          style={{
            width: '20%',
            height: 2,
            color: '#000',
          }}
        />
        <span>社群帳號註冊&快速登入</span>
        <hr
          style={{
            width: '20%',
            height: 2,
            color: '#000',
          }}
        />
      </div>
      <div className={cx('thirdPartyBlock', 'mx-2')}>
        <div
          className={cx(
            'row',
            'd-flex',
            'justify-content-evenly',
            'mt-3',
            'm-auto'
          )}
        >
          <div
            className={cx('col-3', 'loginWayHeight', 'p-0')}
            onClick={() => {
              setLoginByThirdParty('line');
            }}
          >
            <Icons.Line
              width={75}
              height={75}
              onClick={() => {
                lineSignIn();
              }}
            />
          </div>
          {/* <div
            className={cx('col-3', 'loginWayHeight', 'p-0')}
            onClick={() => {
              setLoginByThirdParty('fb');
            }}
          >
            <div
              className={cx('w-100', 'h-100')}
              onClick={() => {
                FBLogin();
              }}
            >
              <Icons.Facebook width={75} height={75} />
            </div>
          </div> */}
          <div
            id="googleSign"
            className={cx('col-3', 'mx-1', 'loginWayHeight', 'p-0')}
            onClick={() => {
              setLoginByThirdParty('google');
            }}
          >
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              buttonText=""
              disabled={
                loginByThirdParty === 'google' || loginByThirdParty === ''
                  ? false
                  : true
              }
              redirectUri={`${process.env.REACT_APP_URL}#/home/`}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              render={(renderProps) => (
                <div
                  className={cx('w-100', 'h-100')}
                  onClick={renderProps.onClick}
                >
                  <Icons.Google width={75} height={75} />
                </div>
              )}
            />
          </div>
        </div>
        <p
          className={cx('mt-2', 'text-start')}
          onClick={() => {
            console.log(lineProfile);
          }}
        >
          ✽以社群登入表示您同意凱基搜集您的使用者名稱、email等個人資料
        </p>
      </div>
      <div
        className={cx(
          'd-flex',
          'justify-content-between',
          'fs-10px',
          'mt-3',
          'mx-5',
          'pb-3'
        )}
      >
        <a>
          <span
            className={cx('buttonFrag', 'hyperLinkText')}
            onClick={() => {
              window.location.href =
                'https://www.kgi.com.tw/zh-tw/others/statement-of-use-of-personal-information';
            }}
          >
            個資法告知事項
          </span>
        </a>

        <a>
          <span
            className={cx('buttonFrag', 'hyperLinkText')}
            onClick={() => {
              window.location.href =
                'https://www.kgi.com.tw/zh-tw/others/privacy-policy';
            }}
          >
            隱私權保護聲明
          </span>
        </a>

        <a>
          <span
            className={cx('buttonFrag', 'hyperLinkText')}
            onClick={() => {
              window.location.href =
                'https://www.kgi.com.tw/zh-tw/others/confidentiality-statement';
            }}
          >
            保密措施
          </span>
        </a>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{errMsg}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>確認</Button>
        </Modal.Footer>
      </Modal>
      <NoticeModel
        isOpen={noticeModelShow}
        handleClose={() => {
          setNoticeModelShow(false);
        }}
        title={'需授權電子郵件帳號'}
        content={`請重新整理並點選line登入，授權電子郵件帳號，若您仍無法成功登入請嘗試  \n 開啟line APP 至「設定」>「我的帳號」>「連動中的應用程式」停止提供權限。 \n 再返回模擬交易重新註冊，並授權電子郵件帳號。`}
      />
      <SubmitModel
        isOpen={submitModalShow}
        handleClose={() => {
          setSubmitModalShow(false);
          setTimeout(() => {
            setLoginByThirdParty('');
          }, 200);
        }}
        isThirdPartyLogin={loginByThirdParty}
        thirdWayProfile={
          loginByThirdParty === 'google'
            ? googleProfile
            : loginByThirdParty === 'line'
            ? lineProfile
            : fbProfile
        }
        setPageButton={setPageButton}
        setUserIslogin={setUserIslogin}
        setLoginInfo={setLoginInfo}
      />
    </div>
  );
};

export default Verify;
