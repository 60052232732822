import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Container } from 'react-bootstrap';
import { taskListType } from '../../api/newApi/getTaskList';
import { Column } from 'react-table';
import { GameIndexDataType } from '../../model/index';
import api from '../../api';
import { CampaignType } from '../../api/newApi/getCampaignQuery';
import style from './index.module.css';
import { Page } from '../../model';
import { Table } from '../../components/DataTable';

const cx = classNames.bind(style);

export interface GameIndex {
  cuid: string | null;
  campaignName: string | null;
  CampaignQueryLoading: boolean;
  refreshTime: string;
  CampaignQuery: CampaignType[];
  setUserTaskRefresh: () => void;
  userTaskList: taskListType[];
  campaignActive: boolean;
  loading?: boolean;
  handleGameCampaign: (
    cuid: string,
    campaignName: string,
    code: string,
    userName: string,
    m_type: string
  ) => void;
  setPageButton: (page: Page) => void;
  setCampaignShow: (show: boolean) => void;
}

const GameIndex: React.FC<GameIndex> = ({
  campaignName,
  cuid,
  refreshTime,
  userTaskList,
  setUserTaskRefresh,
  handleGameCampaign,
  CampaignQuery,
  CampaignQueryLoading,
  campaignActive,
  setPageButton,
  setCampaignShow,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [decodedCode, setDecodedCode] = useState<string | null>(null);

  //把時間字串轉換成ISO格式的時間字串 並且把時間字串加上23:59:59.999
  const RegEndTime = (dateString: string): string => {
    const date = new Date(dateString);
    date.setHours(23, 59, 59, 999);
    return date.toISOString();
  };
  const updatedCampaignQuery = CampaignQuery.map((campaign) => ({
    ...campaign,
    reg_end: campaign.reg_end ? RegEndTime(campaign.reg_end) : null,
  }));

  const isValidBase64 = (str: string) => {
    const base64Regex = /^[A-Za-z0-9+/=]*$/;
    return base64Regex.test(str) && str.length % 4 === 0;
  };
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedCode = urlParams.get('code');
  
    if (encodedCode) {
      if (isValidBase64(encodedCode)) {
        try {
          const decoded = atob(encodedCode);
          console.log('Decoded Base64 string:', decoded);
          setDecodedCode(decoded);
        } catch (e) {
          console.error('Failed to decode Base64:', e);
        }
      } else {
        console.log('Using encodedCode as is:', encodedCode);
        setDecodedCode(encodedCode);
      }
    } else {
      console.error('Encoded code is missing from URL.');
    }
  }, []);
  
  useEffect(() => {
    console.log('當前的CampaignQuery:', CampaignQuery);
    console.log('解碼的 decodedCode:', decodedCode);
  
    const activeGame = updatedCampaignQuery.find(game => game.code === decodedCode);
    if (activeGame) {
      console.log('當下URL中的活動 activeGame:', activeGame);
    } else {
      console.warn('未找到匹配的 activeGame');
    }
  }, [CampaignQuery, decodedCode]);
  

  const activeGame = updatedCampaignQuery.find(game => game.code === decodedCode);
  const isRegStatus = activeGame ? (
    activeGame.reg_end && new Date() > new Date(activeGame.reg_end) ? (
      <span>競賽報名已截止，歡迎體驗【模擬交易】</span>
    ) : (
      <span>
        【{activeGame.name}】
        {activeGame.reg_start ? new Date(activeGame.reg_start).toLocaleDateString() : ''} ~
        {activeGame.reg_end ? new Date(activeGame.reg_end).toLocaleDateString() : ''}
        <br />
        請點選左上方功能列報名
      </span>
    )
  ) : '競賽報名已截止，歡迎體驗【模擬交易】';

  const now = new Date();
  const isWithinOneMonthAfterEnd = (d_end: string) => {
    const DistrationEnd = new Date(d_end);
    DistrationEnd.setHours(23, 59, 59, 999);
//抓一個月後  
    const oneMonthAfterEnd = new Date(d_end);
    oneMonthAfterEnd.setMonth(oneMonthAfterEnd.getMonth() + 1);
  
    return now <= oneMonthAfterEnd;
  };
  
  const combinedData = Array.from(new Set([
    ...updatedCampaignQuery.filter(v => v.signed && v.code !== 'RealTime' && isWithinOneMonthAfterEnd(v.d_end)),
    ...(activeGame && isWithinOneMonthAfterEnd(activeGame.d_end) ? [activeGame] : [])
  ]));
  
  useEffect(() => {
    setTimeout(() => {
      setToggle(!toggle);
    }, 30 * 1000);
  }, [toggle]);

  const columnsForActiveCampaign = React.useMemo<Column<GameIndexDataType>[]>(
    () => [
      {
        Header: '模擬競賽',
        accessor: 'name',
        disableSortBy: true,
        id: 'custommizeClassName_col-4',
        Cell: (cellInfo) => (
          <span style={{ whiteSpace: 'pre-line' }}>
            {cellInfo.cell.row.original.name}
          </span>
        ),
      },
      {
        Header: '活動期間',
        accessor: 'd_start_end',
        disableSortBy: true,
        id: 'custommizeClassName_col-5',
        Cell: ({ row }) => {
          const { reg_start, reg_end, d_start, d_end } = row.original;
          return (
            <span style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>
              報名:{reg_start ? new Date(reg_start).toLocaleDateString('zh-TW', { month: '2-digit', day: '2-digit' }) : ''}
              ~
              {reg_end ? new Date(reg_end).toLocaleDateString('zh-TW', { month: '2-digit', day: '2-digit' }) : ''}
              <br />
              競賽:{d_start ? new Date(d_start).toLocaleDateString('zh-TW', { month: '2-digit', day: '2-digit' }) : ''}
              ~
              {d_end ? new Date(d_end).toLocaleDateString('zh-TW', { month: '2-digit', day: '2-digit' }) : ''}
            </span>
          );
        }
      },
      {
        Header: '選擇活動',
        accessor: 'action',
        disableSortBy: true,
        id: 'custommizeClassName_col-3',
        Cell: (cellInfo) => {
          const { d_start, status, regstatus, reg_end, signed, reg_start, d_end } = cellInfo.cell.row.original;
          const eventStartDate = new Date(d_start).toLocaleDateString('zh-TW', { month: '2-digit', day: '2-digit' });
          const formattedRegStart = reg_start ? RegEndTime(reg_start) : '';
          const formattedRegEnd = reg_end ? RegEndTime(reg_end) : '';
          const handleLogin = () => {
            api.applyCampaign({
              phone: cellInfo.cell.row.original.phone as string,
              email: cellInfo.cell.row.original.email,
              code: cellInfo.cell.row.original.action as string,
            }).then((e) => {
              if (e.err === '0000') {
                const cu_id = e.cu_id as string;
                const { name, action, code, m_type } = cellInfo.cell.row.original;
                const user_name = e.user_name || '';
                handleGameCampaign(
                  cu_id,
                  name,
                  code,
                  user_name,
                  m_type,
                );
              }
            });
          };
          return (
            <>
              {regstatus && !signed ? (
                <button className={cx('startGame-btn')} onClick={() => setCampaignShow(true)}>
                  報名
                </button>
              ) : status && signed ? (
                <button
                  className={cx('startGame-btn')}
                  onClick={handleLogin}
                >登入</button>
              ) : signed && !status && new Date() < new Date(d_start) ? (
                <p style={{ fontSize: '11px', marginTop: '10px' }}>{eventStartDate}開放登入</p>
              ) : status && !signed || !signed && new Date() > new Date(formattedRegEnd) ? (
                <span>報名競賽已截止</span>
              ) : !signed && new Date() < new Date(formattedRegStart) ? (
                <span>報名尚未開始</span>
              ) : (
                '競賽活動已結束，查詢競賽排名，請至左側功能列'
              )}
            </>
          );
        }
      },
    ],
    []
  );


  //模擬競賽Table (下方)
  const columns = React.useMemo<Column<GameIndexDataType>[]>(
    () => [
      {
        Header: '非競賽',
        accessor: 'name',
        disableSortBy: true,
        id: 'custommizeClassName_col-4',
        Cell: (cellInfo) => (
          <span style={{ whiteSpace: 'pre-line' }}>
            {cellInfo.cell.row.original.name}
          </span>
        ),
      },
      {
        Header: '活動期間',
        accessor: 'd_start_end',
        disableSortBy: true,
        id: 'custommizeClassName_col-5',
      },
      {
        Header: '選擇活動',
        accessor: 'action',
        disableSortBy: true,
        id: 'custommizeClassName_col-3',
        Cell: (cellInfo) => (
          <>
            {cellInfo.cell.row.original.status ? (
              <button
                className={cx('startGame-btn')}
                onClick={() => {
                  api
                    .applyCampaign({
                      phone: cellInfo.cell.row.original.phone as string,
                      email: cellInfo.cell.row.original.email,
                      code: cellInfo.cell.row.original.action as string,
                    })
                    .then((e) => {
                      if (e.err === '0000') {
                        const cu_id = e.cu_id as string;
                        const { name, action, code, m_type } =
                          cellInfo.cell.row.original;
                        const campaignName = `${name}`;
                        const user_name = e.user_name || ('' as string);
                        handleGameCampaign(
                          cu_id,
                          campaignName,
                          code,
                          user_name,
                          m_type
                        );
                      }
                    });
                }}
              >
                登入
              </button>
            ) : (
              '非活動期間'
            )}
          </>
        ),
      },
    ],
    []
  );

  return (
    <Container className={cx('container-left')} fluid>
      {campaignActive ? (
        <>
          <Table
            columns={columnsForActiveCampaign}
            data={combinedData}
            loading={CampaignQueryLoading}
            statusMessage={isRegStatus}
          />
          <Table
            columns={columns}
            data={updatedCampaignQuery.filter((v) => v.signed).filter(
              (v) => v.code === 'RealTime'
            )}
            loading={CampaignQueryLoading}
          />
        </>
      ) : (
        <>
          <div className={cx('text-color', 'col-4', 'mt-3', 'mb-2')}>
            <img
              className="img-fluid"
              style={{ maxHeight: '40px' }}
              src={require('../../static/image/GameIndexNonActiveLogin.png')}
              alt="applyGameContest"
            />
          </div>
          <Table
            columns={columns}
            data={updatedCampaignQuery.filter((v) => v.signed).filter(
              (v) => v.code === 'RealTime'
            )}
            loading={CampaignQueryLoading}
          />
          <div className={cx('text-color', 'col-4', 'mt-3', 'mb-2')}>
            <img
              className="img-fluid"
              style={{ maxHeight: '40px' }}
              src={require('../../static/image/GameIndexNonActiveCampaignLogin.png')}
              alt="applyGameContest"
            />
          </div>
          <div>非競賽期間，敬請期待！</div>
        </>
      )}

      <div className={cx('text-color', 'col-4', 'mt-3', 'mb-1')}>
        <img
          className="img-fluid"
          style={{ maxHeight: '40px' }}
          src={require('../../static/image/規則說明.png')}
          alt="gameRule"
        />
      </div>
      <div className={cx('list-decimal', 'text-secondary', 'small', 'mb-3')}>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            模擬交易：免費提供使用，模擬環境體驗市場交易無涉實際盈虧。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">模擬資金：非競賽提供1,000萬元初始模擬金；模擬競賽初始模擬金請參照各活動辦法規定。</div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            下單金額限制：當日下單金額(含尚未成交的在途下單)不得超過現金資產總額。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            入金：每日登入可入金一次20萬元；競賽活動無入金機制。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            反灰鍵：未提供的功能反灰呈現，更完整功能歡迎開戶體驗。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            交易時間：周一~周五證交所公告之交易日，委託 8:30~13:30，撮合
            9:00~13:30。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            現股當沖：預設開放此交易資格，當日買進且當天收盤前賣掉，視為現股當沖，若現股先賣出須按［現先賣］且收盤前須買進沖掉。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            信用當沖：本模擬不提供融資/融券之信用當沖。
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0">◆</div>
          <div className="flex-grow-1">
            重置帳戶：點選後將清除所有模擬交易紀錄。
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GameIndex;
