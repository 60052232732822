import Carousel from 'react-bootstrap/Carousel';
import classNames from 'classnames/bind';

import style from './index.module.css';

const cx = classNames.bind(style);

function CarouselBlock() {
  return (
    <Carousel>
      <Carousel.Item>
        <div
          className={cx('carsouelItem','m-auto')}
          onClick={() => {
            window.open('https://www.youtube.com/@KGISIA.channel')
          }}
        ></div>
      </Carousel.Item>   
      <Carousel.Item>
        <div
          className={cx('carsouelItem2','m-auto')}
          onClick={() => {
            window.open('https://www.youtube.com/playlist?list=PLQMOucYPyMXZiyIoaDpoi4tO74mbdjXS3');
          }}
        ></div>
      </Carousel.Item> 
      <Carousel.Item>
        <div
          className={cx('carsouelItem3')}
          onClick={() => {
            window.open('https://eoa.kgi.com.tw/OOA/index.aspx?Source=E240401&_gl=1*1hee11x*_gcl_au*MTE2MTU4MDI0MS4xNzMwNjk5OTMw*_ga*MTUwMjQ2ODM1Ny4xNzA2ODY0MjAw*_ga_K1BGJY4WFR*MTczNTEwODg5Ni4yOTkuMS4xNzM1MTA4OTY2LjYwLjAuMA')
          }}
        ></div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBlock;
